// JS файл отвечающий за цветные квадратики в верху страницы

export default function mainCategory() {
  const mainListMenu = document.querySelector('.iflex__list');
  const billingBlock = document.querySelector('.new-billing');
  const mainMenuBorder = mainListMenu.querySelectorAll('.iflex__list-item-border');

  const CUBE_ITEMS = document.querySelectorAll('.cube');


  mainListMenu.addEventListener('click', function (event) {
    let target = event.target;

    mainMenuBorder.forEach(function (item) {
      item.classList.remove('iflex__border-active');
    });

    target.classList.add('iflex__border-active');
  });

  const iflexList = document.querySelector('.iflex__list');
  const technologyAll = document.querySelector('.iflex__right-wrapper');

  const nextBtn = document.querySelector('.iflex__btn-next');
  const prevBtn = document.querySelector('.iflex__btn-back');

  prevBtn.addEventListener('click', () => {
    const active = iflexList.querySelector('.iflex-active');
    const prevEl = active.previousElementSibling;

    const border = active.querySelector('.iflex__list-item-border');
    const borderActive = prevEl.querySelector('.iflex__list-item-border');

    const activeItem = technologyAll.querySelector('.iflex__active');
    const prevItem = activeItem.previousElementSibling;

    if (prevEl && prevEl.classList.contains('iflex__list-item')) {
      border.classList.remove('iflex__border-active');
      active.classList.remove('iflex-active');
      borderActive.classList.add('iflex__border-active');
      prevEl.classList.add('iflex-active');

      activeItem.classList.remove('iflex__active');
      prevItem.classList.add('iflex__active');
    }
  })

  nextBtn.addEventListener('click', () => {
    const active = iflexList.querySelector('.iflex-active');
    const nextEl = active.nextElementSibling;

    const border = active.querySelector('.iflex__list-item-border');
    const borderActive = nextEl.querySelector('.iflex__list-item-border');

    const activeItem = technologyAll.querySelector('.iflex__active');
    const nextItem = activeItem.nextElementSibling;


    if (nextEl && nextEl.classList.contains('iflex__list-item')) {
      border.classList.remove('iflex__border-active');
      active.classList.remove('iflex-active');
      borderActive.classList.add('iflex__border-active');
      nextEl.classList.add('iflex-active');

      activeItem.classList.remove('iflex__active');
      nextItem.classList.add('iflex__active');
    }
  });

  $(function () {
    $('.iflex__list').on('click', '.iflex__list-item:not(.iflex-active)', function () {
      $(this).addClass('iflex-active').siblings('.iflex__list-item').removeClass('iflex-active')
      $('.iflex__right-wrapper').find('.iflex__item').removeClass('iflex__active').eq($(this).index()).addClass('iflex__active');

      if (!billingBlock.classList.contains('iflex__active')) {
        CUBE_ITEMS.forEach((item) => {
          item.style = '';
        });
      }
    })
  });
}
