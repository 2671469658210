// JS файл цветные квадратики на главной странице

export default function modal() {
  // const productsBlock = document.querySelector('.products-block');
  const productsItem = document.querySelectorAll('.products-block__item');
  const modalItem = document.querySelector('.products-block__modal');
  const modalText = modalItem.querySelectorAll('.products-block__modal-text');
  const modatTitle = modalItem.querySelector('.products-block__modal-text--title');
  const modalMsg = modalItem.querySelector('.products-block__modal-text--msg');
  const modalClose = document.querySelector('.products-block__modal-btn-close');
  const bodyPage = document.querySelector('.body-page');


  // window.addEventListener('resize', function() {
  //   let width = document.documentElement.clientWidth; //Ширина экрана

  //   if (width <= 1199) {
  //     console.log('ТУТА')
  //     productsItem.forEach((item) => {
  //       const textColor = item.querySelector('.products-block__title');

  //       let title = item.getAttribute('data-title');
  //       let msg = item.getAttribute('data-msg');
  //       let bgColor = window.getComputedStyle(item, null).getPropertyValue('background-color');
  //       let color = window.getComputedStyle(textColor, null).getPropertyValue('color');

  //       item.addEventListener('click', (event) => {
  //         event.stopPropagation();
  //         $(document).off('scroll');

  //         modalItem.style.backgroundColor = bgColor;
  //         modalText.forEach((itemText) => {
  //           itemText.style.color = color;
  //         });

  //         modatTitle.textContent = title;
  //         modalMsg.textContent = msg;

  //         // productsBlock.classList.add('dark--second');
  //         modalItem.classList.add('products-block__modal-active');
  //         bodyPage.classList.add('body-page--dark');
  //         bodyPage.style.overflow = 'hidden';
  //         // $('.body-page').addClass('body-page--scroll-hidden');

  //         $('html, body').stop().animate({
  //           scrollTop: $('#products').offset().top - 63
  //         }, 600, 'swing')
  //       });
  //     });

  //     modalClose.addEventListener('click', function () {
  //       bodyPage.classList.remove('body-page--dark');
  //       bodyPage.style.overflow = '';
  //       modalItem.classList.remove('products-block__modal-active');
  //       // productsBlock.classList.remove('dark--second');
  //     });

  //     // $(document).on('scroll', function() {
  //     //   modalItem.classList.remove('products-block__modal-active');
  //     //   productsBlock.classList.remove('dark--second');
  //     // })

  //     $(document).on('mouseup', function (e) {
  //       let container = $('.products-block__modal');

  //       if (!container.is(e.target) && container.has(e.target).length === 0) {
  //         bodyPage.style.overflow = '';
  //         bodyPage.classList.remove('body-page--dark');
  //         container.removeClass('products-block__modal-active');
  //         // productsBlock.classList.remove('dark--second');
  //       }
  //     });

  //     return;
  //   }
  // })

  if (window.matchMedia("(min-width: 280px) and (max-width: 1199px)").matches) {
    productsItem.forEach((item) => {
      const textColor = item.querySelector('.products-block__title');

      let title = item.getAttribute('data-title');
      let msg = item.getAttribute('data-msg');
      let bgColor = window.getComputedStyle(item, null).getPropertyValue('background-color');
      let color = window.getComputedStyle(textColor, null).getPropertyValue('color');

      item.addEventListener('click', () => {
        $(document).off('scroll');

        let linkMsg = msg.includes('Идем в магазин') ? `<a class="products-block__text--link" target="_blank" href="./documents/goshoping/index.html">&laquo;Идем в магазин&raquo;</a>, &laquo;Выставкомы и конкурсы&raquo;` : msg;
        let linkMsgBilling = title.includes('айФлекс Биллинг') ? `<p class="products-block__title"><a class="products-block__text--link" target="_blank" href="./documents/billing/index.html">айФлекс Биллинг</a></p>` : title;

        modalItem.style.backgroundColor = bgColor;
        modalText.forEach((itemText) => {
          itemText.style.color = color;
        });

        modatTitle.innerHTML = linkMsgBilling;
        modalMsg.innerHTML = linkMsg;

        // productsBlock.classList.add('dark--second');
        modalItem.classList.add('products-block__modal-active');
        bodyPage.classList.add('body-page--dark');
        bodyPage.style.overflow = 'hidden';
        // $('.body-page').addClass('body-page--scroll-hidden');

        $('html, body').stop().animate({
          scrollTop: $('#products').offset().top - 63
        }, 600, 'swing')
      });
    });

    modalClose.addEventListener('click', function () {
      bodyPage.classList.remove('body-page--dark');
      bodyPage.style.overflow = '';
      modalItem.classList.remove('products-block__modal-active');
      // productsBlock.classList.remove('dark--second');
    });

    // $(document).on('scroll', function() {
    //   modalItem.classList.remove('products-block__modal-active');
    //   productsBlock.classList.remove('dark--second');
    // })

    $(document).on('mouseup', function (e) {
      let container = $('.products-block__modal');

      if (!container.is(e.target) && container.has(e.target).length === 0) {
        bodyPage.style.overflow = '';
        bodyPage.classList.remove('body-page--dark');
        container.removeClass('products-block__modal-active');
        // productsBlock.classList.remove('dark--second');
      }
    });
  }
}
