// JS файл Меню и все слова крутилки

export default function menu() {
  $(document).on('DOMContentLoaded ', function () {

    let workHref = window.location.href;

    let width = document.documentElement.clientWidth; //Ширина экрана
    let height = document.documentElement.clientHeight; //Высота экрана

    const mainMenuBtn = document.querySelector('.new-main-menu__btn--menu');
    const mainMenuList = document.querySelector('.new-main-menu__list');
    const pageBody = document.querySelector('.body-page');

    // const aboutBlock = document.querySelector('#about');

    const caseBilling = document.querySelector('.case-billing');
    const billingWrapper = document.querySelector('.new-billing__wrapper');
    const orchestratorWrapper = document.querySelector('.new-orchestrator__img-wrapper');
    const busWrapper = document.querySelector('.new-bus__wrapper-item');
    const paymentWrapper = document.querySelector('.new-payment__img-wrapper');
    const paymentText = document.querySelector('.new-payment__text--desktop');
    const iflexList = document.querySelector('.iflex__list');
    const helloBlock = document.querySelector('.hello-block');

    const systemsImplementation = document.querySelector('.systems-implementation');

    const imgWorking = document.querySelector('.new-payment__img-working');
    const imgBest = document.querySelector('.new-billing__img-best');
    const clickHelp = document.querySelector('.iflex__click-help');
    const decision = document.querySelector('.new-bus__img');
    const progressImg = document.querySelector('.new-about__img-second--left');
    const helpImgAbout = document.querySelector('.new-about__img-second--right');

    const payment = document.querySelector('.new-payment');
    const orchestrator = document.querySelector('.new-orchestrator');
    const bus = document.querySelector('.new-bus');

    const servicesImg = document.querySelectorAll('.services__img');
    const analysisReengineeringImg = document.querySelector('.analysis-reengineering__img');
    const engineeringImg = document.querySelector('.engineering__img-right');
    const newDevelopmentImg = document.querySelector('.new-development__img-right');
    const systemsImplementationImg = document.querySelector('.systems-implementation__img-right');
    const systemIntegrationImg = document.querySelector('.system-integration__img-right');
    const systemsImplementationImgLeft = document.querySelector('.systems-implementation__img-left');
    const inspectionAuditImgRigth = document.querySelector('.inspection-audit__img-rigth');
    const sapProductsImgLeft = document.querySelector('.sap-products__img-left');
    const sapProductsimgRight = document.querySelector('.sap-products__img-right');
    const ibmProductsImgLeft = document.querySelector('.ibm-products__img-left');
    const ibmProductsImgRight = document.querySelector('.ibm-products__img-right');
    const redhatProductsImgLeft = document.querySelector('.redhat-products__img-left');
    const stackImgLeft = document.querySelector('.stack__img-left');
    const newContactsImgLeft = document.querySelector('.new-contacts__img-left');
    const newContactsImgRight = document.querySelector('.new-contacts__img-right');
    const stackImgLeftSecond = document.querySelector('.stack__img-left-second');

    // WORK PAGE WORD
    const workImgLeft = document.querySelector('.work__img-left');
    const newCompanyLifeImgLeft = document.querySelector('.new-company-life__img-left');
    const mttImg = document.querySelector('.mtt__img');
    const vimpelcomImg = document.querySelector('.vimpelcom__img-right');
    const megafonImg = document.querySelector('.megafon__img-right');
    const worldOperatorImg = document.querySelector('.world-operator__img-right');
    const operatorNetworkImg = document.querySelector('.operator-network__img-right');
    const largeBankImg = document.querySelector('.large-bank__img-right');
    const salesImg = document.querySelector('.sales__img-right');
    const newVacanciesImgLeft = document.querySelector('.new-vacancies__img-left');
    const newVacanciesimgLeftSecond = document.querySelector('.new-vacancies__img-left-second');
    const newVacanciesImgRightSecond = document.querySelector('.new-vacancies__img-right-second');
    const increditBankImg = document.querySelector('.incredit-bank__img');
    const newCompanyLifeImgRight = document.querySelector('.new-company-life__img-right');
    const retailImg = document.querySelector('.retail__img-right');
    const telegraphImg = document.querySelector('.telegraph__img');
    const mtsImgRight = document.querySelector('.mts__img-right');

    const btnIflex = document.querySelector('.iflex__btn');
    const btnIflexDown = document.querySelector('.iflex__btn--down');

    servicesImg.forEach(item => {
      if (!workHref.includes('work')) {
        window.addEventListener('resize', function() {
          let width = document.documentElement.clientWidth; //Ширина экрана
          let height = document.documentElement.clientHeight; //Высота экрана

          // console.log(`Ширина: ${width}`);
          // console.log(`Высота: ${height}`);

          if (width >= 1903) {
            item.style = '';
          }

          if (width < 1903) {
            item.style.display = 'block';
            item.style.width = '214px';
          }

          if (width < 1903 && width > 1524) {
            item.style.left = '-700px';
          }

          if (width < 1700) {
            item.style.left = '-630px';
            item.style.width = '130px';
          }

          if (width <= 1440) {
            item.style.display = 'none';
          }

          if (width <= 1381) {
            item.style.display = 'none';
          }

          if (width === 1269) {
            item.style.display = 'none';
          }

          if (width < 1269) {
            item.style.display = 'none';
          }
        })
        // ===================



        // console.log(`Ширина: ${width}`);
        // console.log(`Высота: ${height}`);

        if (width >= 1903) {
          item.style = '';
        }

        if (width < 1903) {
          item.style.display = 'block';
          item.style.width = '214px';
        }

        if (width < 1903 && width > 1524) {
          item.style.left = '-700px';
        }

        if (width < 1700) {
          item.style.left = '-630px';
          item.style.width = '130px';
        }

        if (width <= 1440) {
          item.style.display = 'none';
        }

        if (width <= 1381) {
          item.style.display = 'none';
        }

        if (width === 1269) {
          item.style.display = 'none';
        }

        if (width < 1269) {
          item.style.display = 'none';
        }
        // ===================
      }
    });

    if (workHref.includes('work')) {
      window.addEventListener('resize', function () {
        let width = document.documentElement.clientWidth; //Ширина экрана
        let height = document.documentElement.clientHeight; //Высота экрана

        // console.log(`Ширина: ${width}`);
        // console.log(`Высота: ${height}`);

        if (width >= 1903) {
          newCompanyLifeImgLeft.style = '';

          workImgLeft.style = '';

          newVacanciesImgLeft.style = '';
          mttImg.style = '';
          vimpelcomImg.style = '';
          megafonImg.style = '';
          worldOperatorImg.style = '';
          operatorNetworkImg.style = '';
          largeBankImg.style = '';
          salesImg.style = '';
          newVacanciesimgLeftSecond.style = '';
          newVacanciesImgRightSecond.style = '';
          increditBankImg.style = '';
          newCompanyLifeImgRight.style = '';
          retailImg.style = '';
          telegraphImg.style = '';
          mtsImgRight.style = '';
        }

        if (width < 1903) {
          newCompanyLifeImgLeft.style = '';

          workImgLeft.style.display = 'block';
          workImgLeft.style.left = '-170px';
          workImgLeft.style.top = '60px';

          newVacanciesImgLeft.style.left = '-170px';
          newVacanciesImgLeft.style.width = '160px';
          newVacanciesImgLeft.style.display = 'block';
          mttImg.style = '';
          vimpelcomImg.style = '';
          megafonImg.style = '';
          worldOperatorImg.style = '';
          operatorNetworkImg.style = '';
          largeBankImg.style = '';
          salesImg.style = '';
          newVacanciesimgLeftSecond.style = '';
          newVacanciesImgRightSecond.style = '';
          newCompanyLifeImgRight.style = '';
          retailImg.style = '';
          telegraphImg.style = '';
          increditBankImg.style = '';
          mtsImgRight.style = '';
        }

        if (width < 1903 && width > 1524) {
          newCompanyLifeImgLeft.style.display = 'block';
          // newCompanyLifeImgLeft.style.left = '-165px';
          // newCompanyLifeImgLeft.style.width = '150px';

          workImgLeft.style.display = 'block';
          workImgLeft.style.left = '-170px';
          workImgLeft.style.top = '60px';
        }

        if (width === 1524 || width === 1600) {
          newCompanyLifeImgLeft.style.left = '-160px';
          newCompanyLifeImgLeft.style.width = '150px';

          workImgLeft.style.left = '-165px';
          workImgLeft.style.top = '60px';

          newVacanciesImgLeft.style.left = '-165px';
          newVacanciesImgLeft.style.width = '150px';
          largeBankImg.style.right = '-165px';
          increditBankImg.style.right = '-165px';
          newCompanyLifeImgRight.style.right = '-150px';
          worldOperatorImg.style.right = '-150px';
          newVacanciesimgLeftSecond.style.left = '-160px';
          telegraphImg.style.right = '-160px';
        }

        if (width < 1524 && width > 1269) {
          newCompanyLifeImgLeft.style.left = '-160px';
          newCompanyLifeImgLeft.style.width = '150px';

          workImgLeft.style.left = '-165px';
          workImgLeft.style.top = '60px';

          newVacanciesImgLeft.style.left = '-165px';
          newVacanciesImgLeft.style.width = '150px';

          largeBankImg.style.right = '-165px';
          increditBankImg.style.right = '-165px';
          newCompanyLifeImgRight.style.right = '-150px';
          worldOperatorImg.style.right = '-150px';
          newVacanciesimgLeftSecond.style.left = '-160px';
          telegraphImg.style.right = '-160px';
        }

        if (width <= 1440) {
          newCompanyLifeImgLeft.style.display = 'none';
          workImgLeft.style.display = 'none';

          newVacanciesImgLeft.style.display = 'none';
          mttImg.style.display = 'none';
          vimpelcomImg.style.display = 'none';
          megafonImg.style.display = 'none';
          worldOperatorImg.style.display = 'none';
          operatorNetworkImg.style.display = 'none';
          largeBankImg.style.display = 'none';
          salesImg.style.display = 'none';
          newVacanciesimgLeftSecond.style.display = 'none';
          newVacanciesImgRightSecond.style.display = 'none';
          increditBankImg.style.display = 'none';
          newCompanyLifeImgRight.style.display = 'none';
          retailImg.style.display = 'none';
          telegraphImg.style.display = 'none';
        }

        if (width <= 1381) {
          newCompanyLifeImgLeft.style.display = 'none';
          workImgLeft.style.display = 'none';

          newVacanciesImgLeft.style.display = 'none';
          mttImg.style.display = 'none';
          vimpelcomImg.style.display = 'none';
          megafonImg.style.display = 'none';
          worldOperatorImg.style.display = 'none';
          operatorNetworkImg.style.display = 'none';
          largeBankImg.style.display = 'none';
          salesImg.style.display = 'none';
          newVacanciesimgLeftSecond.style.display = 'none';
          newVacanciesImgRightSecond.style.display = 'none';
          increditBankImg.style.display = 'none';
          newCompanyLifeImgRight.style.display = 'none';
          retailImg.style.display = 'none';
          telegraphImg.style.display = 'none';
          mtsImgRight.style.display = 'none';
        }
      })

      if (width >= 1903) {
        newCompanyLifeImgLeft.style = '';

        workImgLeft.style = '';

        newVacanciesImgLeft.style = '';
        mttImg.style = '';
        vimpelcomImg.style = '';
        megafonImg.style = '';
        worldOperatorImg.style = '';
        operatorNetworkImg.style = '';
        largeBankImg.style = '';
        salesImg.style = '';
        newVacanciesimgLeftSecond.style = '';
        newVacanciesImgRightSecond.style = '';
        increditBankImg.style = '';
        newCompanyLifeImgRight.style = '';
        retailImg.style = '';
        telegraphImg.style = '';
      }

      if (width < 1903) {
        newCompanyLifeImgLeft.style = '';

        workImgLeft.style.display = 'block';
        workImgLeft.style.left = '-170px';
        workImgLeft.style.top = '60px';

        newVacanciesImgLeft.style.left = '-170px';
        newVacanciesImgLeft.style.width = '160px';
        newVacanciesImgLeft.style.display = 'block';
        mttImg.style = '';
        vimpelcomImg.style = '';
        megafonImg.style = '';
        worldOperatorImg.style = '';
        operatorNetworkImg.style = '';
        largeBankImg.style = '';
        salesImg.style = '';
        newVacanciesimgLeftSecond.style = '';
        newVacanciesImgRightSecond.style = '';
        newCompanyLifeImgRight.style = '';
        retailImg.style = '';
        telegraphImg.style = '';
        increditBankImg.style = '';
      }

      if (width < 1903 && width > 1524) {
        newCompanyLifeImgLeft.style.display = 'block';
        // newCompanyLifeImgLeft.style.left = '-165px';
        // newCompanyLifeImgLeft.style.width = '150px';

        workImgLeft.style.display = 'block';
        workImgLeft.style.left = '-170px';
        workImgLeft.style.top = '60px';
      }

      if (width === 1524 || width === 1600) {
        newCompanyLifeImgLeft.style.left = '-160px';
        newCompanyLifeImgLeft.style.width = '150px';

        workImgLeft.style.left = '-165px';
        workImgLeft.style.top = '60px';

        newVacanciesImgLeft.style.left = '-165px';
        newVacanciesImgLeft.style.width = '150px';
        largeBankImg.style.right = '-165px';
        increditBankImg.style.right = '-165px';
        newCompanyLifeImgRight.style.right = '-150px';
        worldOperatorImg.style.right = '-150px';
        newVacanciesimgLeftSecond.style.left = '-160px';
        telegraphImg.style.right = '-160px';
      }

      if (width < 1524 && width > 1269) {
        newCompanyLifeImgLeft.style.left = '-160px';
        newCompanyLifeImgLeft.style.width = '150px';

        workImgLeft.style.left = '-165px';
        workImgLeft.style.top = '60px';

        newVacanciesImgLeft.style.left = '-165px';
        newVacanciesImgLeft.style.width = '150px';

        largeBankImg.style.right = '-165px';
        increditBankImg.style.right = '-165px';
        newCompanyLifeImgRight.style.right = '-150px';
        worldOperatorImg.style.right = '-150px';
        newVacanciesimgLeftSecond.style.left = '-160px';
        telegraphImg.style.right = '-160px';
      }

      if (width <= 1440) {
        newCompanyLifeImgLeft.style.display = 'none';
        workImgLeft.style.display = 'none';

        newVacanciesImgLeft.style.display = 'none';
        mttImg.style.display = 'none';
        vimpelcomImg.style.display = 'none';
        megafonImg.style.display = 'none';
        worldOperatorImg.style.display = 'none';
        operatorNetworkImg.style.display = 'none';
        largeBankImg.style.display = 'none';
        salesImg.style.display = 'none';
        newVacanciesimgLeftSecond.style.display = 'none';
        newVacanciesImgRightSecond.style.display = 'none';
        increditBankImg.style.display = 'none';
        newCompanyLifeImgRight.style.display = 'none';
        retailImg.style.display = 'none';
        telegraphImg.style.display = 'none';
      }

      if (width <= 1381) {
        newCompanyLifeImgLeft.style.display = 'none';
        workImgLeft.style.display = 'none';

        newVacanciesImgLeft.style.display = 'none';
        mttImg.style.display = 'none';
        vimpelcomImg.style.display = 'none';
        megafonImg.style.display = 'none';
        worldOperatorImg.style.display = 'none';
        operatorNetworkImg.style.display = 'none';
        largeBankImg.style.display = 'none';
        salesImg.style.display = 'none';
        newVacanciesimgLeftSecond.style.display = 'none';
        newVacanciesImgRightSecond.style.display = 'none';
        increditBankImg.style.display = 'none';
        newCompanyLifeImgRight.style.display = 'none';
        retailImg.style.display = 'none';
        telegraphImg.style.display = 'none';
        mtsImgRight.style.display = 'none';
      }
    }

    if (!workHref.includes('work')) {
      window.addEventListener('resize', function() {
        let width = document.documentElement.clientWidth; //Ширина экрана
        let height = document.documentElement.clientHeight; //Высота экрана

        // console.log(`Ширина: ${width}`);
        // console.log(`Высота: ${height}`);

        if (width >= 1903) {
          caseBilling.style = '';
          billingWrapper.style = '';
          orchestratorWrapper.style = '';
          busWrapper.style = '';
          paymentWrapper.style = '';
          paymentText.style = '';

          payment.style = '';
          orchestrator.style = '';
          bus.style = '';

          iflexList.style = '';
          helloBlock.style = '';

          imgBest.style.display = 'block';
          imgWorking.style = '';
          imgBest.style.bottom = '100px';

          progressImg.style = '';
          helpImgAbout.style = '';

          btnIflex.style = '';
          btnIflexDown.style = '';

          decision.style = '';
          analysisReengineeringImg.style.display = 'block';
          engineeringImg.style.display = 'block';
          newDevelopmentImg.style.display = 'block';
          systemsImplementationImg.style.display = 'block';
          systemIntegrationImg.style.display = 'block';
          systemsImplementationImgLeft.style = '';
          inspectionAuditImgRigth.style.right = '-250px';
          inspectionAuditImgRigth.style.display = 'block';
          sapProductsImgLeft.style = '';
          sapProductsimgRight.style.right = '-200px';
          sapProductsimgRight.style.display = 'block';
          ibmProductsImgLeft.style.left = '-800px';
          ibmProductsImgLeft.style.display = 'block';
          ibmProductsImgRight.style.right = '-190px';
          ibmProductsImgRight.style.display = 'block';
          redhatProductsImgLeft.style.left = '-700px';
          redhatProductsImgLeft.style.display = 'block';
          stackImgLeft.style.left = '-730px';
          stackImgLeftSecond.style.left = '-730px';
          newContactsImgLeft.style.display = 'block';
          newContactsImgLeft.style.left = '-230px';
          newContactsImgLeft.style.bottom = '35px';
          newContactsImgRight.style.display = 'block';
        }

        if (width < 1903) {
          iflexList.style.top = 'calc(50px + 172 * ((100vw - 1200px) / 720))';
          helloBlock.style.top = 'calc(50px + 172 * ((100vw - 1200px) / 720))';

          imgWorking.style.display = 'block';
          imgWorking.style.right = 'calc(-100px - 170 * ((100vw - 1200px) / 720))';
          imgWorking.style.bottom = 'calc(0px + 150 * ((100vw - 1200px) / 720))';

          imgBest.style.bottom = 'calc(150px + 150 * ((100vw - 1200px) / 720))';

          imgBest.style.display = 'block';
          clickHelp.style.display = 'block';

          btnIflex.style.top = 'calc(10px + 140 * ((100vw - 1200px) / 720))';
          btnIflexDown.style.top = 'calc(347px + 201 * ((100vw - 1200px) / 720))';
          decision.style = '';

          progressImg.style.display = 'block';
          progressImg.style.left = '-300px';
          progressImg.style.width = '330px';
          helpImgAbout.style.display = 'block';
          helpImgAbout.style.right = 'calc(-100px - 130 * ((100vw - 1200px) / 720))';

          analysisReengineeringImg.style.display = 'block';
          engineeringImg.style.display = 'block';
          newDevelopmentImg.style.display = 'block';
          systemsImplementationImg.style.display = 'block';
          systemsImplementationImg.style.right = '-150px';
          systemIntegrationImg.style.display = 'block';
          systemsImplementationImgLeft.style.display = 'block';
          systemsImplementationImgLeft.style.left = '-665px';
          systemsImplementationImgLeft.style.width = '200px';
          inspectionAuditImgRigth.style.right = '-150px';
          inspectionAuditImgRigth.style.display = 'block';
          sapProductsImgLeft.style.left = '-800px';
          sapProductsImgLeft.style.bottom = '-290px';
          sapProductsImgLeft.style.display = 'block';
          sapProductsimgRight.style.right = '-130px';
          sapProductsimgRight.style.display = 'block';
          ibmProductsImgLeft.style.left = '-630px';
          ibmProductsImgLeft.style.display = 'block';
          ibmProductsImgRight.style.right = '-150px';
          ibmProductsImgRight.style.display = 'block';
          redhatProductsImgLeft.style.left = '-650px';
          redhatProductsImgLeft.style.display = 'block';
          stackImgLeft.style.left = '-650px';
          stackImgLeft.style.display = 'block';
          stackImgLeftSecond.style.left = '-650px';
          stackImgLeftSecond.style.display = 'block';
          newContactsImgLeft.style.display = 'block';
          newContactsImgLeft.style.left = '-165px';
          newContactsImgLeft.style.bottom = '35px';
          newContactsImgRight.style.display = 'block';
        }

        if (width < 1903 && width > 1524) {
          caseBilling.style.transform = 'scale(0.9)';
          caseBilling.style.top = '-50px';
          billingWrapper.style.top = '-100px';

          orchestratorWrapper.style = '';

          busWrapper.style.marginTop = '35px';
          busWrapper.style.transform = '';

          paymentWrapper.style.marginTop = '-45px';
          paymentWrapper.style.transform = 'scale(0.8)';
          paymentText.style.bottom = '45px';

          payment.style = '';
          orchestrator.style = '';
          bus.style = '';

          imgBest.style.display = 'block';
          imgWorking.style.display = 'block';

          imgWorking.style.bottom = '0';

          decision.style.right = '-150px';

          analysisReengineeringImg.style.right = '-150px';
          engineeringImg.style.right = '-150px';
        }

        if (width < 1700) {
          sapProductsImgLeft.style.left = '-690px';
          sapProductsImgLeft.style.bottom = '-290px';
          progressImg.style.left = '-230px';
          progressImg.style.width = '270px';
        }

        if (width === 1524 || width === 1600) {
          caseBilling.style.transform = 'scale(0.7)';
          caseBilling.style.top = '-100px';
          billingWrapper.style.top = '-150px';

          orchestratorWrapper.style = '';

          busWrapper.style.marginTop = '35px';
          busWrapper.style.transform = '';

          paymentWrapper.style.marginTop = '-45px';
          paymentWrapper.style.transform = 'scale(0.8)';
          paymentText.style.bottom = '45px';

          payment.style = '';
          orchestrator.style = '';
          bus.style = '';

          imgBest.style.display = 'block';
          imgWorking.style.display = 'block';

          decision.style.right = '-100px';

          analysisReengineeringImg.style.right = '-150px';
          engineeringImg.style.right = '-150px';
          systemsImplementationImg.style.right = '-150px';
          systemsImplementationImgLeft.style.left = '-670px';
          systemsImplementationImgLeft.style.width = '200px';
          inspectionAuditImgRigth.style.right = '-190px';
          sapProductsImgLeft.style.left = '-690px';
          sapProductsImgLeft.style.bottom = '-290px';
          sapProductsimgRight.style.right = '-150px';
          ibmProductsImgLeft.style.left = '-630px';
          ibmProductsImgRight.style.right = '-150px';
          redhatProductsImgLeft.style.left = '-630px';
          stackImgLeft.style.left = '-650px';
          stackImgLeftSecond.style.left = '-650px';
          newContactsImgLeft.style.left = '-160px';
          newContactsImgLeft.style.bottom = '70px';

          progressImg.style.width = '240px';
          progressImg.style.left = '-210px';
        }

        if (width < 1524 && width > 1269) {
          caseBilling.style.transform = 'scale(0.6)';
          caseBilling.style.top = '-110px';
          billingWrapper.style.top = '-210px';

          orchestratorWrapper.style.marginTop = '-20px';
          // orchestratorWrapper.style.transform = 'scale(0.9)';

          paymentWrapper.style.marginTop = '-77px';
          paymentWrapper.style.transform = 'scale(0.7)';
          paymentText.style.bottom = '135px';

          busWrapper.style.marginTop = '-25px';
          busWrapper.style.transform = 'scale(0.8)';

          payment.style.paddingTop = '40px';
          orchestrator.style.paddingTop = '40px';
          bus.style.paddingTop = '40px';

          imgBest.style.display = 'block';

          decision.style.right = '-100px';

          analysisReengineeringImg.style.right = '-150px';
          engineeringImg.style.right = '-150px';
          systemsImplementationImg.style.right = '-150px';
          systemsImplementationImgLeft.style.left = '-670px';
          systemsImplementationImgLeft.style.width = '200px';
          inspectionAuditImgRigth.style.right = '-190px';
          sapProductsImgLeft.style.left = '-690px';
          sapProductsImgLeft.style.bottom = '-290px';
          sapProductsimgRight.style.right = '-150px';
          ibmProductsImgLeft.style.left = '-630px';
          ibmProductsImgRight.style.right = '-150px';
          redhatProductsImgLeft.style.left = '-630px';
          stackImgLeft.style.left = '-650px';
          stackImgLeftSecond.style.left = '-650px';
          newContactsImgLeft.style.left = '-160px';
          newContactsImgLeft.style.bottom = '70px';

          progressImg.style.width = '240px';
          progressImg.style.left = '-210px';
        }

        if (width === 1519) {
          busWrapper.style.marginTop = '30px';
          busWrapper.style.transform = 'scale(1)';

          orchestratorWrapper.style.marginTop = '0px';

          // imgBest.style.bottom = '250px';
          // imgBest.style.display = 'block';
          // clickHelp.style.display = 'block';
        }

        if (width <= 1440) {
          imgWorking.style.display = 'none';
          imgBest.style.display = 'none';
          clickHelp.style.display = 'none';
          decision.style.display = 'none';
          progressImg.style.display = 'none';
          helpImgAbout.style.display = 'none';

          analysisReengineeringImg.style.display = 'none';
          engineeringImg.style.display = 'none';
          newDevelopmentImg.style.display = 'none';
          systemsImplementationImg.style.display = 'none';
          systemIntegrationImg.style.display = 'none';
          systemsImplementationImgLeft.style.display = 'none';
          inspectionAuditImgRigth.style.display = 'none';
          sapProductsImgLeft.style.display = 'none';
          sapProductsimgRight.style.display = 'none';
          ibmProductsImgLeft.style.display = 'none';
          ibmProductsImgRight.style.display = 'none';
          redhatProductsImgLeft.style.display = 'none';
          stackImgLeft.style.display = 'none';
          stackImgLeftSecond.style.display = 'none';
          newContactsImgLeft.style.display = 'none';
          newContactsImgRight.style.display = 'none';
        }

        if (width <= 1381) {
          imgWorking.style.display = 'none';
          imgBest.style.display = 'none';
          clickHelp.style.display = 'none';
          decision.style.display = 'none';
          progressImg.style.display = 'none';
          helpImgAbout.style.display = 'none';

          analysisReengineeringImg.style.display = 'none';
          engineeringImg.style.display = 'none';
          newDevelopmentImg.style.display = 'none';
          systemsImplementationImg.style.display = 'none';
          systemIntegrationImg.style.display = 'none';
          systemsImplementationImgLeft.style.display = 'none';
          inspectionAuditImgRigth.style.display = 'none';
          sapProductsImgLeft.style.display = 'none';
          sapProductsimgRight.style.display = 'none';
          ibmProductsImgLeft.style.display = 'none';
          ibmProductsImgRight.style.display = 'none';
          redhatProductsImgLeft.style.display = 'none';
          stackImgLeft.style.display = 'none';
          stackImgLeftSecond.style.display = 'none';
          newContactsImgLeft.style.display = 'none';
          newContactsImgRight.style.display = 'none';
        }

        if (width === 1269) {
          caseBilling.style.transform = 'scale(0.6)';
          caseBilling.style.top = '-140px';
          billingWrapper.style.top = '-270px';

          orchestratorWrapper.style.marginTop = '-20px';
          // orchestratorWrapper.style.transform = 'scale(0.9)';

          paymentWrapper.style.marginTop = '-77px';
          paymentWrapper.style.transform = 'scale(0.7)';
          paymentText.style.bottom = '135px';

          busWrapper.style.marginTop = '-25px';
          busWrapper.style.transform = 'scale(0.8)';

          payment.style.paddingTop = '40px';
          orchestrator.style.paddingTop = '40px';
          bus.style.paddingTop = '40px';

          imgBest.style.display = 'none';
          imgWorking.style.display = 'none';
          decision.style.display = 'none';
          progressImg.style.display = 'none';
          helpImgAbout.style.display = 'none';
          engineeringImg.style.display = 'none';
          newDevelopmentImg.style.display = 'none';
          systemsImplementationImg.style.display = 'none';
          systemIntegrationImg.style.display = 'none';
          systemsImplementationImgLeft.style.display = 'none';
          newContactsImgRight.style.display = 'none';
        }

        if (width < 1269) {
          caseBilling.style = '';
          billingWrapper.style = '';
          orchestratorWrapper.style = '';
          busWrapper.style = '';
          paymentWrapper.style = '';
          paymentText.style = '';

          payment.style = '';
          orchestrator.style = '';
          bus.style = '';

          iflexList.style = '';
          helloBlock.style = '';

          btnIflex.style = '';
          btnIflexDown.style = '';

          decision.style.display = 'none';
          progressImg.style.display = 'none';
          helpImgAbout.style.display = 'none';
          engineeringImg.style.display = 'none';
          newDevelopmentImg.style.display = 'none';
          systemsImplementationImg.style.display = 'none';
          systemIntegrationImg.style.display = 'none';
          systemsImplementationImgLeft.style.display = 'none';
          newContactsImgRight.style.display = 'none';
        }
      })
      // ===================



      // console.log(`Ширина: ${width}`);
      // console.log(`Высота: ${height}`);

      if (width >= 1903) {
        caseBilling.style = '';
        billingWrapper.style = '';
        orchestratorWrapper.style = '';
        busWrapper.style = '';
        paymentWrapper.style = '';
        paymentText.style = '';

        payment.style = '';
        orchestrator.style = '';
        bus.style = '';

        iflexList.style = '';
        helloBlock.style = '';

        imgBest.style.display = 'block';
        imgWorking.style = '';
        imgBest.style.bottom = '100px';

        progressImg.style = '';
        helpImgAbout.style = '';

        btnIflex.style = '';
        btnIflexDown.style = '';

        decision.style = '';
        analysisReengineeringImg.style.display = 'block';
        engineeringImg.style.display = 'block';
        newDevelopmentImg.style.display = 'block';
        systemsImplementationImg.style.display = 'block';
        systemIntegrationImg.style.display = 'block';
        systemsImplementationImgLeft.style = '';
        inspectionAuditImgRigth.style.right = '-250px';
        inspectionAuditImgRigth.style.display = 'block';
        sapProductsImgLeft.style = '';
        sapProductsimgRight.style.right = '-200px';
        sapProductsimgRight.style.display = 'block';
        ibmProductsImgLeft.style.left = '-800px';
        ibmProductsImgLeft.style.display = 'block';
        ibmProductsImgRight.style.right = '-190px';
        ibmProductsImgRight.style.display = 'block';
        redhatProductsImgLeft.style.left = '-700px';
        redhatProductsImgLeft.style.display = 'block';
        stackImgLeft.style.left = '-730px';
        stackImgLeftSecond.style.left = '-730px';
        newContactsImgLeft.style.display = 'block';
        newContactsImgLeft.style.left = '-230px';
        newContactsImgLeft.style.bottom = '35px';
        newContactsImgRight.style.display = 'block';
      }

      if (width < 1903) {
        iflexList.style.top = 'calc(50px + 172 * ((100vw - 1200px) / 720))';
        helloBlock.style.top = 'calc(50px + 172 * ((100vw - 1200px) / 720))';

        imgWorking.style.display = 'block';
        imgWorking.style.right = 'calc(-100px - 170 * ((100vw - 1200px) / 720))';
        imgWorking.style.bottom = 'calc(0px + 150 * ((100vw - 1200px) / 720))';

        imgBest.style.bottom = 'calc(150px + 150 * ((100vw - 1200px) / 720))';

        imgBest.style.display = 'block';
        clickHelp.style.display = 'block';

        btnIflex.style.top = 'calc(10px + 140 * ((100vw - 1200px) / 720))';
        btnIflexDown.style.top = 'calc(347px + 201 * ((100vw - 1200px) / 720))';
        decision.style = '';

        progressImg.style.display = 'block';
        progressImg.style.left = '-300px';
        helpImgAbout.style.display = 'block';
        helpImgAbout.style.right = 'calc(-100px - 130 * ((100vw - 1200px) / 720))';

        analysisReengineeringImg.style.display = 'block';
        engineeringImg.style.display = 'block';
        newDevelopmentImg.style.display = 'block';
        systemsImplementationImg.style.display = 'block';
        systemsImplementationImg.style.right = '-150px';
        systemIntegrationImg.style.display = 'block';
        systemsImplementationImgLeft.style.display = 'block';
        systemsImplementationImgLeft.style.left = '-665px';
        systemsImplementationImgLeft.style.width = '200px';
        inspectionAuditImgRigth.style.right = '-150px';
        inspectionAuditImgRigth.style.display = 'block';
        sapProductsImgLeft.style.left = '-800px';
        sapProductsImgLeft.style.bottom = '-290px';
        sapProductsImgLeft.style.display = 'block';
        sapProductsimgRight.style.right = '-130px';
        sapProductsimgRight.style.display = 'block';
        ibmProductsImgLeft.style.left = '-630px';
        ibmProductsImgLeft.style.display = 'block';
        ibmProductsImgRight.style.right = '-150px';
        ibmProductsImgRight.style.display = 'block';
        redhatProductsImgLeft.style.left = '-650px';
        redhatProductsImgLeft.style.display = 'block';
        stackImgLeft.style.left = '-650px';
        stackImgLeft.style.display = 'block';
        stackImgLeftSecond.style.left = '-650px';
        stackImgLeftSecond.style.display = 'block';
        newContactsImgLeft.style.display = 'block';
        newContactsImgLeft.style.left = '-165px';
        newContactsImgLeft.style.bottom = '35px';
        newContactsImgRight.style.display = 'block';
      }

      if (width < 1903 && width > 1524) {
        caseBilling.style.transform = 'scale(0.9)';
        caseBilling.style.top = '-50px';
        billingWrapper.style.top = '-100px';

        orchestratorWrapper.style = '';

        busWrapper.style.marginTop = '35px';
        busWrapper.style.transform = '';

        paymentWrapper.style.marginTop = '-45px';
        paymentWrapper.style.transform = 'scale(0.8)';
        paymentText.style.bottom = '45px';

        payment.style = '';
        orchestrator.style = '';
        bus.style = '';

        imgBest.style.display = 'block';
        imgWorking.style.display = 'block';

        imgWorking.style.bottom = '0';

        decision.style.right = '-150px';

        analysisReengineeringImg.style.right = '-150px';
        engineeringImg.style.right = '-150px';
      }

      if (width < 1700) {
        sapProductsImgLeft.style.left = '-690px';
        sapProductsImgLeft.style.bottom = '-290px';
        progressImg.style.left = '-230px';
        progressImg.style.width = '270px';
      }

      if (width === 1524 || width === 1600) {
        caseBilling.style.transform = 'scale(0.7)';
        caseBilling.style.top = '-100px';
        billingWrapper.style.top = '-150px';

        orchestratorWrapper.style = '';

        busWrapper.style.marginTop = '35px';
        busWrapper.style.transform = '';

        paymentWrapper.style.marginTop = '-45px';
        paymentWrapper.style.transform = 'scale(0.8)';
        paymentText.style.bottom = '45px';

        payment.style = '';
        orchestrator.style = '';
        bus.style = '';

        imgBest.style.display = 'block';
        imgWorking.style.display = 'block';

        decision.style.right = '-100px';

        analysisReengineeringImg.style.right = '-150px';
        engineeringImg.style.right = '-150px';
        systemsImplementationImg.style.right = '-150px';
        systemsImplementationImgLeft.style.left = '-670px';
        systemsImplementationImgLeft.style.width = '200px';
        inspectionAuditImgRigth.style.right = '-190px';
        sapProductsImgLeft.style.left = '-690px';
        sapProductsImgLeft.style.bottom = '-290px';
        sapProductsimgRight.style.right = '-150px';
        ibmProductsImgLeft.style.left = '-630px';
        ibmProductsImgRight.style.right = '-150px';
        redhatProductsImgLeft.style.left = '-630px';
        stackImgLeft.style.left = '-650px';
        stackImgLeftSecond.style.left = '-650px';
        newContactsImgLeft.style.left = '-160px';
        newContactsImgLeft.style.bottom = '70px';

        progressImg.style.width = '240px';
        progressImg.style.left = '-210px';
      }

      if (width < 1524 && width > 1269) {
        caseBilling.style.transform = 'scale(0.6)';
        caseBilling.style.top = '-110px';
        billingWrapper.style.top = '-210px';

        orchestratorWrapper.style.marginTop = '-20px';
        // orchestratorWrapper.style.transform = 'scale(0.9)';

        paymentWrapper.style.marginTop = '-77px';
        paymentWrapper.style.transform = 'scale(0.7)';
        paymentText.style.bottom = '135px';

        busWrapper.style.marginTop = '-25px';
        busWrapper.style.transform = 'scale(0.8)';

        payment.style.paddingTop = '40px';
        orchestrator.style.paddingTop = '40px';
        bus.style.paddingTop = '40px';

        imgBest.style.display = 'block';

        decision.style.right = '-100px';

        analysisReengineeringImg.style.right = '-150px';
        engineeringImg.style.right = '-150px';
        systemsImplementationImg.style.right = '-150px';
        systemsImplementationImgLeft.style.left = '-670px';
        systemsImplementationImgLeft.style.width = '200px';
        inspectionAuditImgRigth.style.right = '-190px';
        sapProductsImgLeft.style.left = '-690px';
        sapProductsImgLeft.style.bottom = '-290px';
        sapProductsimgRight.style.right = '-150px';
        ibmProductsImgLeft.style.left = '-630px';
        ibmProductsImgRight.style.right = '-150px';
        redhatProductsImgLeft.style.left = '-630px';
        stackImgLeft.style.left = '-650px';
        stackImgLeftSecond.style.left = '-650px';
        newContactsImgLeft.style.left = '-160px';
        newContactsImgLeft.style.bottom = '70px';

        progressImg.style.width = '240px';
        progressImg.style.left = '-210px';
      }

      if (width === 1519) {
        busWrapper.style.marginTop = '30px';
        busWrapper.style.transform = 'scale(1)';

        orchestratorWrapper.style.marginTop = '0px';

        // imgBest.style.bottom = '250px';
        // imgBest.style.display = 'block';
        // clickHelp.style.display = 'block';
      }

      if (width <= 1440) {
        imgWorking.style.display = 'none';
        imgBest.style.display = 'none';
        clickHelp.style.display = 'none';
        decision.style.display = 'none';
        progressImg.style.display = 'none';
        helpImgAbout.style.display = 'none';

        analysisReengineeringImg.style.display = 'none';
        engineeringImg.style.display = 'none';
        newDevelopmentImg.style.display = 'none';
        systemsImplementationImg.style.display = 'none';
        systemIntegrationImg.style.display = 'none';
        systemsImplementationImgLeft.style.display = 'none';
        inspectionAuditImgRigth.style.display = 'none';
        sapProductsImgLeft.style.display = 'none';
        sapProductsimgRight.style.display = 'none';
        ibmProductsImgLeft.style.display = 'none';
        ibmProductsImgRight.style.display = 'none';
        redhatProductsImgLeft.style.display = 'none';
        stackImgLeft.style.display = 'none';
        stackImgLeftSecond.style.display = 'none';
        newContactsImgLeft.style.display = 'none';
        newContactsImgRight.style.display = 'none';
      }

      if (width <= 1381) {
        imgWorking.style.display = 'none';
        imgBest.style.display = 'none';
        clickHelp.style.display = 'none';
        decision.style.display = 'none';
        progressImg.style.display = 'none';
        helpImgAbout.style.display = 'none';

        analysisReengineeringImg.style.display = 'none';
        engineeringImg.style.display = 'none';
        newDevelopmentImg.style.display = 'none';
        systemsImplementationImg.style.display = 'none';
        systemIntegrationImg.style.display = 'none';
        systemsImplementationImgLeft.style.display = 'none';
        inspectionAuditImgRigth.style.display = 'none';
        sapProductsImgLeft.style.display = 'none';
        sapProductsimgRight.style.display = 'none';
        ibmProductsImgLeft.style.display = 'none';
        ibmProductsImgRight.style.display = 'none';
        redhatProductsImgLeft.style.display = 'none';
        stackImgLeft.style.display = 'none';
        stackImgLeftSecond.style.display = 'none';
        newContactsImgLeft.style.display = 'none';
        newContactsImgRight.style.display = 'none';
      }

      if (width === 1269) {
        caseBilling.style.transform = 'scale(0.6)';
        caseBilling.style.top = '-140px';
        billingWrapper.style.top = '-270px';

        orchestratorWrapper.style.marginTop = '-20px';
        // orchestratorWrapper.style.transform = 'scale(0.9)';

        paymentWrapper.style.marginTop = '-77px';
        paymentWrapper.style.transform = 'scale(0.7)';
        paymentText.style.bottom = '135px';

        busWrapper.style.marginTop = '-25px';
        busWrapper.style.transform = 'scale(0.8)';

        payment.style.paddingTop = '40px';
        orchestrator.style.paddingTop = '40px';
        bus.style.paddingTop = '40px';

        imgBest.style.display = 'none';
        imgWorking.style.display = 'none';
        decision.style.display = 'none';
        progressImg.style.display = 'none';
        helpImgAbout.style.display = 'none';
        engineeringImg.style.display = 'none';
        newDevelopmentImg.style.display = 'none';
        systemsImplementationImg.style.display = 'none';
        systemIntegrationImg.style.display = 'none';
        systemsImplementationImgLeft.style.display = 'none';
        newContactsImgRight.style.display = 'none';
      }

      if (width < 1269) {
        caseBilling.style = '';
        billingWrapper.style = '';
        orchestratorWrapper.style = '';
        busWrapper.style = '';
        paymentWrapper.style = '';
        paymentText.style = '';

        payment.style = '';
        orchestrator.style = '';
        bus.style = '';

        iflexList.style = '';
        helloBlock.style = '';

        btnIflex.style = '';
        btnIflexDown.style = '';

        decision.style.display = 'none';
        progressImg.style.display = 'none';
        helpImgAbout.style.display = 'none';
        engineeringImg.style.display = 'none';
        newDevelopmentImg.style.display = 'none';
        systemsImplementationImg.style.display = 'none';
        systemIntegrationImg.style.display = 'none';
        systemsImplementationImgLeft.style.display = 'none';
        newContactsImgRight.style.display = 'none';
      }
      // ===================
    }


    mainMenuBtn.addEventListener('click', function() {

      mainMenuBtn.classList.toggle('btn-active-menu');


      if (!mainMenuList.classList.contains('main-menu-active')) {
        pageBody.classList.add('dark--third');
        mainMenuList.classList.remove('main-menu-hide');
        mainMenuList.classList.add('main-menu-active');
        pageBody.classList.remove('body-page--scroll-hidden');
        pageBody.classList.remove('dark');
        $('.services__list-wrapper').removeClass('services__list-wrapper-active');
        $('.services__container').removeClass('dark');
        $('.expertise__list-wrapper').removeClass('expertise__list-wrapper-active');
        $('.expertise__container').removeClass('dark');
      } else {
        mainMenuList.classList.remove('main-menu-active');
        mainMenuList.classList.add('main-menu-hide');
        pageBody.classList.remove('dark--third');
      }
    });

    window.addEventListener('resize', function() {
      if (pageBody.classList.contains('dark--third') || mainMenuList.classList.contains('main-menu-active')) {
        pageBody.classList.remove('dark--third');
        mainMenuList.classList.remove('main-menu-active');
        mainMenuBtn.classList.remove('btn-active-menu');
      }
    })

    // $(document).on('ready', function() {
    let lastScrollTop = 0;

    $(document).on('scroll', function(event) {
      let posit = $(this).scrollTop();

      if (posit > lastScrollTop) {
        // $('.header-page').addClass('header-page--hide');
        $('.header-page').addClass('header-page--sticky');
        $('.services__drop-down').removeClass('services__drop-down--menu-top');
        $('.expertise__drop-down').removeClass('expertise__drop-down--menu-top');
      } else if (posit <= 1 || posit === -1) {
        $('.header-page').removeClass('header-page--sticky');
      } else {
        $('.header-page').addClass('header-page--sticky');
        // $('.header-page').removeClass('header-page--hide');
        $('.services__drop-down').addClass('services__drop-down--menu-top');
        $('.expertise__drop-down').addClass('expertise__drop-down--menu-top');
      }

      lastScrollTop = posit;
    });
    // });

    // function onScroll(event){
    //   let scrollBlock = $('.scroll-block');

    //   scrollBlock.each(function(_, el) {
    //     let top = $(el).position().top;
    //     let bottom = top + $(el).height();
    //     let scroll = $(window).scrollTop();
    //     let id = $(el).attr('id');

    //     if (scroll > top && scroll < bottom) {
    //       $('.new-main-menu__link--active').removeClass('new-main-menu__link--active');
    //       $('.one-page a[href="#' + id + '"]').addClass('new-main-menu__link--active');
    //     }

    //     if ($(window).scrollTop() + $(window).height() + 10 >= $(document).height()) {
    //       $('.new-main-menu__link--active').removeClass('new-main-menu__link--active');
    //       $('.one-page a[href="#contacts"]').addClass('new-main-menu__link--active');
    //     }

    //     if (scroll === 0) {
    //       $('.one-page').removeClass('new-main-menu__link--active');
    //     }
    //   })

    //   $('.new-main-menu__list').removeClass('main-menu-active');
    //   $('.body-page').removeClass('dark--third');
    //   $('.new-main-menu__btn--menu').removeClass('btn-active-menu');
    // }

    $(document).on('scroll', function() {

      let scrollBlock = $('.scroll-block');

      scrollBlock.each(function(_, el) {
        let top = $(el).position().top;
        let bottom = top + $(el).height();
        let scroll = $(window).scrollTop();
        let id = $(el).attr('id');

        if (scroll > top - 63 && scroll < bottom) {
          $('.new-main-menu__link--active').removeClass('new-main-menu__link--active');
          $('a[href="#' + id + '"]').addClass('new-main-menu__link--active');
        }

        if ($(window).scrollTop() + $(window).height() + 10 >= $(document).height()) {
          $('.new-main-menu__link--active').removeClass('new-main-menu__link--active');
          $('a[href="#contacts"]').addClass('new-main-menu__link--active');
        }

        if (scroll === 0) {
          $('.new-main-menu__link').removeClass('new-main-menu__link--active');
        }
      })

      $('.new-main-menu__list').removeClass('main-menu-active');
      $('.body-page').removeClass('dark--third');
      $('.new-main-menu__btn--menu').removeClass('btn-active-menu');
    });

    if (!workHref.includes('work-eng')) {
      $('.one-page').on('click', function(e) {
        e.preventDefault();
        let target = $(this);

        $(document).off("scroll");

        $('.one-page').each(function () {
          $(this).removeClass('new-main-menu__link--active');
        })
        target.addClass('new-main-menu__link--active');

        // $('.new-main-menu__link').removeClass('new-main-menu__link--active');
        // target.addClass('new-main-menu__link--active');

        $('.new-main-menu__list').removeClass('main-menu-active');
        $('.body-page').removeClass('dark--third');
        $('.new-main-menu__btn--menu').removeClass('btn-active-menu');

        $('html, body').stop().animate({
          scrollTop: $(target.attr('href')).offset().top - 63
        }, 600, 'swing', function() {
          $(document).on("scroll", function() {

            let scrollBlock = $('.scroll-block');

            scrollBlock.each(function(_, el) {
              let top = $(el).position().top;
              let bottom = top + $(el).height();
              let scroll = $(window).scrollTop();
              let id = $(el).attr('id');

              if (scroll > top - 63 && scroll < bottom) {
                $('.new-main-menu__link--active').removeClass('new-main-menu__link--active');
                $('a[href="#' + id + '"]').addClass('new-main-menu__link--active');
              }

              if ($(window).scrollTop() + $(window).height() + 10 >= $(document).height()) {
                $('.new-main-menu__link--active').removeClass('new-main-menu__link--active');
                $('a[href="#contacts"]').addClass('new-main-menu__link--active');
              }

              if (scroll === 0) {
                $('.new-main-menu__link').removeClass('new-main-menu__link--active');
              }
            })

            $('.new-main-menu__list').removeClass('main-menu-active');
            $('.body-page').removeClass('dark--third');
            $('.new-main-menu__btn--menu').removeClass('btn-active-menu');
          });
        });

        return false;
      });
    }
  });
}
