import cube from './cube';
import expertiseTabs from './expertise-tabs';
import mainCategory from './main-category';
import menu from './menu';
import modal from './modal';
import companyLife from './new-company-life';
import vacancies from './new-vacancies';
import workProject from './work-project';
import video from './video';
import products from './products'

let workHref = window.location.href;

if (!workHref.includes('work')) {
  cube();
  expertiseTabs();
  mainCategory();
  menu();
  modal();
  products();
} else {
  menu();
  companyLife();
  video();
  workProject();
  vacancies();
}
