export default function products() {
  const productsItem = document.querySelectorAll('.products-block__item');

  if (window.matchMedia("(min-width: 1200px)").matches) {
    productsItem.forEach((item) => {

      let title = item.getAttribute('data-title');

      let findBillingCoub = title.includes('айФлекс Биллинг')

      if (findBillingCoub) {
        console.log('findBillingCoub', item);
        console.dir(item);
        item.addEventListener('click', () => {
          window.location.href = '/documents/billing/index.html';
        })
      }
    })
  }



}

