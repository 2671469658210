// JS файл Реализованные проекты на странице Работа у нас

export default function workProject() {
  const btnCloseWorkProject = document.querySelectorAll('.modal-work-project-close');
  const modalWorkProject = document.querySelector('.work-project__modal');
  const companyAll = document.querySelectorAll('.work-project__img-wrapper');
  const bodyPage = document.querySelector('.body-page');

  if (window.matchMedia("(min-width: 280px) and (max-width: 1199px)").matches) {
    companyAll.forEach(function(element) {
      element.classList.remove('work-project__active-menu');
    });

    $(document).on('mouseup', function(e) {
      let container = $('.work-project__modal');

      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.removeClass('work-project__open');
        bodyPage.style.overflow = '';
        bodyPage.classList.remove('body-page--dark');

        companyAll.forEach(function (element) {
          element.classList.remove('work-project__active-menu');
        });
      }
    });
  }

  window.addEventListener('resize', function() {
    let width = document.documentElement.clientWidth; //Ширина экрана

    if (width > 1199) {
      bodyPage.style.overflow = '';
      bodyPage.classList.remove('body-page--dark');
      $('.work-project__rtk').addClass('work-project__active-menu');
      return;
    }

    if (width < 1199) {
      companyAll.forEach(function(element) {
        element.classList.remove('work-project__active-menu');
      });

      $(document).on('mouseup', function(e) {
        let container = $('.work-project__modal');

        if (!container.is(e.target) && container.has(e.target).length === 0) {
          container.removeClass('work-project__open');
          bodyPage.style.overflow = '';
          bodyPage.classList.remove('body-page--dark');

          companyAll.forEach(function (element) {
            element.classList.remove('work-project__active-menu');
          });
        }
      });

      return;
    }
  })

  btnCloseWorkProject.forEach(function(item) {
    item.addEventListener('click', function (event) {
      bodyPage.style.overflow = '';
      modalWorkProject.classList.remove('work-project__open');
      modalWorkProject.scrollTop = 0;
      bodyPage.classList.remove('body-page--dark');

      companyAll.forEach(function (element) {
        element.classList.remove('work-project__active-menu');
      });

      event.stopPropagation();
    });
  });


  $(function () {
    $('.work-project__left-content').on('click', 'div:not(.work-project__active-menu)', function () {
      $(this).addClass('work-project__active-menu').siblings().removeClass('work-project__active-menu');
      $('.work-project__right-content').find('.work-project__item').removeClass('work-project__active-block').eq($(this).index()).addClass('work-project__active-block');

      // if (width >= 320 && width <= 1199) {
      //   if ($(this).hasClass('work-project__active-menu')) {

      //     bodyPage.classList.add('body-page--dark');
      //     $('.work-project__right-content').addClass('work-project__open');
      //   }
      // }



      // if (window.matchMedia("(min-width: 1199px)").matches) {
      //   $('html, body').animate({
      //     scrollTop: $('#work-project__modal').position().top - 20
      //   }, 300);

      //   return;
      // }
      let width = document.documentElement.clientWidth; //Ширина экрана

      if (window.matchMedia("(min-width: 280px) and (max-width: 1199px)").matches || (width >= 280 && width <= 1199)) {
        // if ($(this).hasClass('work-project__active-menu')) {

          $('html, body').animate({
            scrollTop: $('#work-project__modal').offset().top - 80
          }, 300);

          bodyPage.style.overflow = 'hidden';
          bodyPage.classList.add('body-page--dark');
          $('.work-project__right-content').addClass('work-project__open');
        // }

        return;
      }
    })
  });
}
