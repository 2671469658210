// JS файл Видео на странице Работа у нас

export default function video() {
  const videosSelect = document.querySelectorAll('.new-company-life__video-select');
  const wrapperIframeAll = document.querySelectorAll('.new-company-life__wrapper-iframe');

  new Flickity('.new-company-life__list-video', {

    accessibility: true,
    adaptiveHeight: false,
    autoPlay: false,
    cellAlign: 'left',
    cellSelector: undefined,
    contain: false,
    draggable: false, //>1
    dragThreshold: 8,
    freeScroll: true,
    freeScrollFriction: 0.09,
    friction: 0.45,
    groupCells: false,
    initialIndex: 0,
    lazyLoad: false,
    percentPosition: true,
    prevNextButtons: false,
    pageDots: false,
    resize: true,
    rightToLeft: false,
    setGallerySize: true,
    watchCSS: false,
    wrapAround: true,
    pauseAutoPlayOnHover: false
  });

  $(function () {
    $('.new-company-life__list-video').on('click', '.new-company-life__list-video-item:not(.new-video-active-list)', function () {
      $(this).addClass('video-active-list').siblings().removeClass('video-active-list')
      $('.new-company-life__video-wrapper').find('.new-company-life__video-select').removeClass('video-active').eq($(this).index()).addClass('video-active');
      wrapperIframeAll.forEach(el => {
        el.innerHTML = ''
      })
    })
  });

  videosSelect.forEach((item, index) => {
    let frame = document.createElement('iframe');
    // let script = document.createElement('script');
    frame.setAttribute('width', '100%');
    frame.setAttribute('height', '100%');
    frame.classList.add('new-company-life__media');
    frame.setAttribute('id', `video-${index}`);

    item.addEventListener('click', function(event) {
      const target = event.currentTarget;
      const wrapperIframeSelect = target.querySelector('.new-company-life__wrapper-iframe');

      if (target.classList.contains('video-active')) {
        let url = item.getAttribute('data-url');
        frame.setAttribute('src', `${url}`);
        wrapperIframeSelect.appendChild(frame);
        // ?rel=0&showinfo=0&autoplay=1
      }
    })
  })

  // $(document).on('mouseup', function (e) {
  //   let container = $('.new-company-life__list-video');
  //   let containerSecond = $('.new-company-life__video-wrapper');

  //   if (!container.is(e.target) && container.has(e.target).length === 0 && !containerSecond.is(e.target) && containerSecond.has(e.target).length === 0) {
  //     wrapperIframeAll.forEach(el => {
  //       el.innerHTML = ''
  //     })
  //   }
  // });
}
