// JS файл цветные квадратики на странице Работа у нас

export default function companyLife() {
  const companyLifeItem = document.querySelectorAll('.new-company-life__item');
  const modalItem = document.querySelector('.new-company-life__modal');
  const modalText = modalItem.querySelectorAll('.new-company-life__modal-text');
  const modatTitle = modalItem.querySelector('.new-company-life__modal-text--title');
  const modalMsg = modalItem.querySelector('.new-company-life__modal-text--msg');
  const modalClose = document.querySelector('.new-company-life__modal-btn-close');
  const bodyPage = document.querySelector('.body-page');

  window.addEventListener('resize', function() {
    let width = document.documentElement.clientWidth;

    if (width >= 280 && width <= 767) {
      companyLifeItem.forEach((item) => {
        const textColor = item.querySelector('.new-company-life__title');

        let title = item.getAttribute('data-title');
        let msg = item.getAttribute('data-msg');
        let bgColor = window.getComputedStyle(item, null).getPropertyValue('background-color');
        let color = window.getComputedStyle(textColor, null).getPropertyValue('color');

        item.addEventListener('click', (event) => {
          event.stopPropagation();
          modalItem.style.backgroundColor = bgColor;
          modalText.forEach((itemText) => {
            itemText.style.color = color;
          });

          modatTitle.textContent = title;
          modalMsg.textContent = msg;

          modalItem.classList.add('new-company-life__modal-active');
          bodyPage.classList.add('body-page--dark-second');
          // bodyPage.style.overflow = 'hidden';

          $('html, body').stop().animate({
            scrollTop: $('#company-life').offset().top - 63
          }, 600, 'swing')
        });
      });

      modalClose.addEventListener('click', function (event) {
        event.stopPropagation();
        modalItem.classList.remove('new-company-life__modal-active');
        bodyPage.classList.remove('body-page--dark-second');
        // bodyPage.style.overflow = '';
      });

      $(document).on('mouseup', function (e) {
        let container = $('.new-company-life__modal');

        if (!container.is(e.target) && container.has(e.target).length === 0) {
          container.removeClass('new-company-life__modal-active');
          bodyPage.classList.remove('body-page--dark-second');
          // bodyPage.style.overflow = '';
        }
      });
    }
  })

  if (window.matchMedia("(min-width: 280px) and (max-width: 767px)").matches) {
    companyLifeItem.forEach((item) => {
      const textColor = item.querySelector('.new-company-life__title');

      let title = item.getAttribute('data-title');
      let msg = item.getAttribute('data-msg');
      let bgColor = window.getComputedStyle(item, null).getPropertyValue('background-color');
      let color = window.getComputedStyle(textColor, null).getPropertyValue('color');

      item.addEventListener('click', (event) => {
        event.stopPropagation();
        modalItem.style.backgroundColor = bgColor;
        modalText.forEach((itemText) => {
          itemText.style.color = color;
        });

        modatTitle.textContent = title;
        modalMsg.textContent = msg;

        modalItem.classList.add('new-company-life__modal-active');
        bodyPage.classList.add('body-page--dark-second');
        // bodyPage.style.overflow = 'hidden';

        $('html, body').stop().animate({
          scrollTop: $('#company-life').offset().top - 63
        }, 600, 'swing')
      });
    });

    modalClose.addEventListener('click', function (event) {
      event.stopPropagation();
      modalItem.classList.remove('new-company-life__modal-active');
      bodyPage.classList.remove('body-page--dark-second');
      // bodyPage.style.overflow = '';
    });

    $(document).on('mouseup', function (e) {
      e.stopPropagation();
      const container = $('.new-company-life__modal');

      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.removeClass('new-company-life__modal-active');
        bodyPage.classList.remove('body-page--dark-second');
        // bodyPage.style.overflow = '';
        return;
      }

      return;
    });
  };
}
