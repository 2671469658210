// JS файл отвечающий за анимацию при наведение на отдельные кубики в разделе билинг на верху страницы

export default function cube() {
  const CUBE_ITEMS = document.querySelectorAll('.cube');


  CUBE_ITEMS.forEach((item) => {
    item.addEventListener('animationstart', () => {
      if (item.classList.add('offEvents')) {
        return;
      }

      item.classList.add('offEvents');
    });

    item.addEventListener('animationend', () => {
      item.classList.remove('offEvents');

      item.addEventListener('click', (event) => {
        let el = event.currentTarget;

        el.style['animation-iteration-count'] = `1`;
        el.style['animation-name'] = 'rotate-item';

        if (el.style['animation-name'] = 'rotate-item') {
          item.classList.remove('offEvents');
        }

        el.addEventListener('animationend', () => {
          el.style['animation-iteration-count'] = '1';
          el.style['animation-fill-mode'] = 'backwards';
          el.style['animation-name'] = 'none';
        });
      });

      item.addEventListener('mouseover', (event) => {
        let el = event.currentTarget;

        el.style['animation-iteration-count'] = 'infinite';
        el.style['animation-play-state'] = 'running';
        el.style['animation-name'] = 'rotate-item';
      });

      item.addEventListener('mouseout', (event) => {
        let el = event.currentTarget;

        el.style['animation-iteration-count'] = `1`;

        el.addEventListener('animationend', () => {
          el.style['animation-iteration-count'] = '1';
          el.style['animation-fill-mode'] = 'backwards';
          el.style['animation-name'] = 'none';
        });

      });
    });
  });
}
