// JS файл Вакансии на стр Работа у нас

export default function vacancies() {
  const vacanciesItems = document.querySelectorAll('.new-vacancies__item');
  const btnsMore = document.querySelectorAll('.new-vacancies__btn--more');

  const vacancies = document.querySelectorAll('.new-vacancies__main-wrapper');

  window.addEventListener('resize', function() {
    vacanciesItems.forEach(function(item) {
      const btnMore = item.querySelector('.new-vacancies__btn--more');
      const container = item.querySelector('.new-vacancies__main-wrapper');

      if (btnMore.classList.contains('new-vacancies__btn--active')) {
        let content = btnMore.nextElementSibling;

        let childs = [...container.children];

        let arrChildHeight = childs.map(function(child) {
          let childHeight = child.offsetHeight;
          return childHeight;
        });

        let sumHeight = arrChildHeight.reduce(function(a, b) {
          return a + b;
        });

        content.style.height = `${sumHeight + 100}px`;
      }

      // btnMore.addEventListener('click', function(event) {
      //   let el = event.currentTarget;
      //   let content = el.nextElementSibling;

      //   let childs = [...container.children];

      //   let arrChildHeight = childs.map(function(child) {
      //     let childHeight = child.offsetHeight;
      //     return childHeight;
      //   });

      //   let sumHeight = arrChildHeight.reduce(function(a, b) {
      //     return a + b;
      //   });

      //   if (el.classList.contains('new-vacancies__btn--active')) {
      //     content.style.height = `0px`;
      //     el.classList.remove('new-vacancies__btn--active');
      //     return;
      //   }

      //   btnsMore.forEach(function(item, index) {
      //     item.classList.remove('new-vacancies__btn--active');
      //   });

      //   vacancies.forEach(function(item) {
      //     item.style.height = '0px';
      //   });

      //   content.style.height = `${sumHeight + 100}px`;
      //   el.classList.add('new-vacancies__btn--active');
      // });
    });
  })

  vacanciesItems.forEach(function(item, index) {
    const btnMore = item.querySelector('.new-vacancies__btn--more');
    const container = item.querySelector('.new-vacancies__main-wrapper');

    btnMore.addEventListener('click', function(event) {
      let el = event.currentTarget;
      let content = el.nextElementSibling;

      let childs = [...container.children];

      let arrChildHeight = childs.map(function(child) {
        let childHeight = child.offsetHeight;
        return childHeight;
      });

      let sumHeight = arrChildHeight.reduce(function(a, b) {
        return a + b;
      });

      if (el.classList.contains('new-vacancies__btn--active')) {
        content.style.height = `0px`;
        el.classList.remove('new-vacancies__btn--active');
        return;
      }

      btnsMore.forEach(function(item, index) {
        item.classList.remove('new-vacancies__btn--active');
      });

      vacancies.forEach(function(item) {
        item.style.height = '0px';
      });

      content.style.height = `${sumHeight + 170}px`;
      el.classList.add('new-vacancies__btn--active');

      if (el.getAttribute('data-index') === `${index + 1}`) {
        if (el.getAttribute('data-index') !== '1') {
          $('html, body').stop().animate({
            scrollTop: $('#new-vacancies').offset().top + 250
          }, 600)

          return;
        }

        $('html, body').stop().animate({
          scrollTop: $('#new-vacancies').offset().top
        }, 600)
      }
    });
  });
}
