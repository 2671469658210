// JS Файл отвечающий за раздел ЭКСПЕРТИЗЫ

export default function expertiseTabs() {
  const dropDownExpertise = document.querySelector('.expertise__drop-down');
  const dropBtnExpertise = dropDownExpertise.querySelector('.expertise__down');
  const expertiseList = document.querySelector('.expertise__list-wrapper--mobile');
  const expertiseContainer = document.querySelector('.expertise__container');


  const dropDownServices = document.querySelector('.services__drop-down');
  const dropBtnServices = dropDownServices.querySelector('.services__down');
  const servicesList = document.querySelector('.services__list-wrapper--mobile');
  const servicesContainer = document.querySelector('.services__container');

  const bodyPage = document.querySelector('.body-page');


  dropDownServices.addEventListener('click', function(event) { //Услуги
    let el = event.currentTarget;

    dropBtnServices.classList.toggle('services__down-active');
    servicesList.classList.toggle('services__list-wrapper-active');
    bodyPage.classList.toggle('dark');
    bodyPage.classList.toggle('body-page--scroll-hidden');

    $('html, body').animate({
      scrollTop: $('#services').offset().top - 50
    }, 300);
  });

  dropDownExpertise.addEventListener('click', function(event) { //Экспертиза
    event.stopPropagation();
    const el = event.currentTarget;

    dropBtnExpertise.classList.toggle('expertise__down-active');
    expertiseList.classList.toggle('expertise__list-wrapper-active');
    bodyPage.classList.toggle('dark');
    bodyPage.classList.toggle('body-page--scroll-hidden-second');

    $('html, body').animate({
      scrollTop: $('#expertise').offset().top - 50
    }, 300);
  });

  // $(document).on('mouseup', function (e) { //Экспертиза
  //   let container = $('.expertise__list-wrapper--mobile');

  //   if (!container.is(e.target) && container.has(e.target).length === 0) {

  //     expertiseBtn.style.pointerEvents = '';
  //     dropBtnExpertise.classList.remove('expertise__down-active');
  //     container.removeClass('expertise__list-wrapper-active');
  //     bodyPage.classList.remove('dark');
  //     bodyPage.classList.remove('body-page--scroll-hidden');

  //     // $(document).off('mouseup');
  //   }
  // });

  $(function () {
    $('.expertise__list').on('click', 'li:not(.active-expertise-menu)', function(event) {

      $(this).addClass('active-expertise-menu').siblings().removeClass('active-expertise-menu');

      $('.expertise__content').find('.expertise__content-item').removeClass('expertise__active').eq($(this).index()).addClass('expertise__active');
    });
  });


  $(function () {
    $('.services__list').on('click', 'li:not(.active-services-menu)', function(event) {
      $(this).addClass('active-services-menu').siblings().removeClass('active-services-menu');

      $('.services__content').find('.services__content-item').removeClass('services__active').eq($(this).index()).addClass('services__active');

      if ($('.engineering').hasClass('services__active')) {
        $('.services__btn').addClass('services__hide');
      } else {
        $('.services__btn').removeClass('services__hide');
      }
    });
  });
}
